// src/components/Navbar.js
import React from 'react';
import { FaBell } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import profile from '../../../assets/1x/tania.jpg';
import { Menu01Icon } from 'hugeicons-react';

function Navbar({ toggleSidebar }) {
  return (
    <nav className="w-fullbg-white shadow-2xl shadow-slate-800 flex items-center justify-between px-6 max-md:px-[4.8%] py-3.5 max-md:py-2.5">
      {/* <header className="flex items-center justify-between h-16 px-6 bg-white shadow-md"> */}
      <div className='flex items-center gap-5 w-[60%]'>
        <div className='cursor-pointer btn-menu'>
          <Menu01Icon/>
        </div>
        <input
          className="bg-gray-100 rounded-md max-md:hidden pl-4 w-[80%] pr-4 py-2 focus:outline-none"
          type="text"
          placeholder="Rechercher"
        />
      </div>
        <div className='flex items-center gap-3'>
            <div className='relative max-md:w-[30px] max-md:h-[30px] w-[4Opx] h-[4Opx] rounded-[50px] overflow-hidden' style={{width:42,height:42}}>
              <img src={profile} alt='#' className='absolute w-full h-full object-cover' />
            </div>
            <div className='flex flex-col max-md:hidden'>
              <span className='text-[1rem] whitespace-nowrap font-semibold'>Franchise It</span>
              <span className='text-[.85rem]'>Admin</span>
            </div>
        </div>
      {/* </header> */}
    </nav>
  );
}

export default Navbar;
