import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner';
import Annimated from '../../components/Annimated';
import jambo from '../../assets/jambotv.PNG';
import articl from '../../assets/1x/article1.pdf';
import article from '../../assets/1x/articlen°21124.pdf';
import article1 from '../../assets/1x/article31124.pdf';
import vidJambo from '../../assets/videos/Publier.mp4';
import actual from '../../assets/1x/page.jpg';
import actual3 from '../../assets/1x/article3.jpeg';
import atc from '../../assets/1x/atl02.png';
import Infolettre from '../../components/Infolettre';
import Publicite from '../../components/Publicite';
import moment from 'moment';
// import { useDispatch, useSelector } from 'react-redux';
// import { getRevueById } from '../../store/revue.slice';

export default function RevueDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    // const rem = id.replace(/no/,'')
    // const dispatch=useDispatch();
    // const substring=[".mp4",".avi",".mkv"]

    // const checkPresence=(text,subb)=>{
    //     return subb?.some((sub)=>text?.includes(sub))
    // }

    // const { RevueId, isSuccess,isLoading } = useSelector((state) => state.revue);
    // useEffect(() => {
    //     dispatch(getRevueById(parseInt(rem)));
    //   }, [dispatch,rem]);
    
    const actualites = [
        {
            id: 4,
            image: actual3,
            type: 'interne',
            url:'',
            videoUrl: '',
            titre: 'Le risque d’effondrement de l’économie canadienne suite à l’annonce de Trump sur les taxes douanières',
            article:'no 31124',
            descrip: "Avant l’arrivée au pouvoir du nouveau Président des États-Unis, Donald Trump, prévue pour le 20 Janvier 2025, des craintes sont ressenties au niveau de l’économie du Canada. Dans un post paru le 25 Novembre 2024 sur son réseau Truth Social, Donald Trump déclare ceci: « Le 20 Janvier, dans le cadre de l’un de mes nombreux premiers décrets, je signerai tous les documents nécessaires pour imposer au Mexique et au Canada des tarifs douaniers de 25% sur tous les produits entrant aux États-Unis »",
            date: new Date(),
            fille: article1
        },
        {
            id: 2,
            image: actual,
            type: 'interne',
            url:'',
            titre: 'DECROISSANCE ET CROISSANCE DES PME AU CANADA : Le cas du Québec',
            article:'no 11124',
            descrip: "Chaque année, près de 7200 entreprises ferment les portes au Québec depuis une bonne décennie. Est-ce le résultat d’une insuffisance en programmes gouvernementaux pour penser le futur d’une telle activité importante pour l’économie ? ",
            date: '2024/11/02',
            videoUrl: '',
            fille: articl
        },
        {
            id: 3,
            image: atc,
            type: 'interne',
            url:'',
            titre: 'LE TAMIS DE L’IMMIGRATION QUÉBÉCOISE PLUS RESSERRÉ',
            article:'no 21124',
            descrip: "L’année suivante sera davantage difficile pour les immigrants qui visent le Québec. L’accessibilité se présente plus réduite à cause de la suspension de deux programmes à savoir le PEQ(Programme de l’Expérience Québécoise) pour les diplômés, et le PRTQ (Programme Régulier des Travailleurs Qualifiés).",
            date: '2024/11/04',
            videoUrl: '',
            fille: article
        }
    ];

    const actualite = actualites.find((item) => item.article === id);

    if (!actualite) {
        return <div className='px-[5%] py-14'>{t('news_not_found')}</div>;
    }
    // console.log(parseInt(rem))
    // console.log(RevueId)

    return (
        <Annimated>
            {/* <Banner title={actualite.titre} /> */}
            <div className='px-[5%] py-12 pt-8 flex gap-[3rem]'>
            <div className='flex flex-col w-[73%] gap-6 max-w-screen-lg max-md:w-full'>
                        <div className='mt-4'>
                            <img
                                src={actualite.image}
                                alt='#'
                                title={actualite.titre}
                                className='w-full h-[600px] max-md:h-fit object-cover object-top rounded-md'
                            />
                        </div>
                   
                    <h1 className='text-[2rem] font-bold text-primary max-md:text-[1.2rem]'>{actualite.title}</h1>
                    <span className='text-black italic'>Article {actualite?.article}</span>
                    <span className='text-gray-500'>{t('published_on')}: {moment(actualite.date).format("YYYY-MM-DD")}</span>
                    <p className='text-[1.1rem] text-gray-700 leading-relaxed' dangerouslySetInnerHTML={{__html:actualite.descrip}}></p>
                    <a href={actualite?.fille} className='px-5 font-bold text-center text-white w-fit  py-2 rounded-md bg-secondary' download>Télécharger l'article {actualite?.article}</a>
                </div>
                {/* <div className='flex flex-col w-[73%] gap-6 max-w-screen-lg max-md:w-full'>
                    {checkPresence(RevueId.doc,substring) ? 
                        <div className='mt-4'>
                            <video
                                src={RevueId.thumbnail}
                                title={RevueId.title}
                                className='w-full h-[500px] max-md:h-fit rounded-md'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                                controls
                            />
                        </div> :
                        <div className='mt-4'>
                            <img
                                src={RevueId.thumbnail}
                                alt='#'
                                title={RevueId.title}
                                className='w-full h-[550px] max-md:h-fit object-cover object-top rounded-md'
                            />
                        </div>
                    }
                   
                    <h1 className='text-[2rem] font-bold text-primary max-md:text-[1.2rem]'>{RevueId.title}</h1>
                    <span className='text-black italic'>Article No {RevueId?.article}</span>
                    <span className='text-gray-500'>{t('published_on')}: {moment(RevueId.created_at).format("YYYY-MM-DD")}</span>
                    <p className='text-[1.1rem] text-gray-700 leading-relaxed' dangerouslySetInnerHTML={{__html:RevueId.description}}></p>
                    <a href={RevueId?.doc} className='px-5 font-bold text-center text-white w-fit  py-2 rounded-md bg-secondary' download>Télécharger l'article No {RevueId?.article}</a>
                </div> */}

                <div className='w-[27%] max-md:hidden flex flex-col gap-8'>
                    <Publicite />
                    <Infolettre />
                </div>
            </div>
        </Annimated>
    );
}
