import React from 'react'
import formad from '../../assets/1x/formadd.jpeg'
import { ArrowDown02Icon, ArrowRight02Icon, ArrowRight03Icon } from 'hugeicons-react'

export default function TemplateFormations() {
    return (
        <div className='mt-2 border-t-4 border-dashed border-secondary pt-4'>
            <h2 className='font-bold text-primary text-[1.5rem] max-md:text-[1.2rem]'>Exportation d’expertise Canada-Cameroun : volet Formation Professionnelle</h2>
            <p className='mt-4 text-[1.1rem] max-md:text-[1rem] text-justify'>
                <img src={formad}className='w-[44%] max-md:w-full float-left' alt='#' />
                <strong className='text-secondary'>La Chambre de Commerce Canada Afrique</strong> à travers son programme d'accompagnement à l'exportation d'expertise et maillage d'affaires de ses membres, a le plaisir de faire la présentation de l'entreprise canadienne @Cuisine santé kids, basée à Toronto et dirigée par le @Chef Dongue Guy .<br></br><br></br>
                Il s'agit d'une expérience réussie d'exportation d'expertise en faveur du CFPHTC. <br></br><br></br>
                Le #CFPHTC est situé à <mark>Douala au Cameroun</mark> et accueille également des apprenants étrangers (Afrique). Il offre des formations spécialisées en Hôtellerie, Restauration, Cuisine Internationale avec une orientation sur les bonnes pratiques culinaires calquées sur le modèle canadien. L’originalité du #CFPHTC est qu’il apporte une formation rigoureuse, pointue, 100% pratique, innovante et axée sur l’entrepreneuriat. 
                Le programme de formation du #CFPHTC offre un stage d’immersion à l’international notamment au Canada 🇨🇦.<br></br><br></br>
                Au terme du stage, le #CFPHTC offre aux apprenants deux options : <br></br>
                <ul>
                    <li className='text-start'><ArrowRight03Icon className='float-left me-3 text-secondary'/> <strong>Incubation-création d’entreprise :</strong> afin de mettre sur le marché une boulangerie, une pâtisserie, un modèle de restauration rapide tropicalisé, à travers un accompagnement complet de la conceptualisation du projet à la commercialisation du produit ( la méthode CP-CP).</li>
                    <li className='text-start'><ArrowRight03Icon className='float-left me-3 text-secondary'/> <strong>Immersion professionnelle temporaire (permis de travail )</strong> dans un écosystème d’Horeca (Hôtellerie, Restauration, Café) au Canada 🇨🇦 en fonction du besoin de main d’œuvre ou de la pénurie dans le secteur. L’avantage pour l’apprenant est qu’il bénéficie d’un apprentissage approfondi qui tient compte de diverses expériences en milieu professionnel.</li>
                </ul><br></br>
                Ce projet d’immersion et d’incubation est soutenu par la <strong className='font-bold text-secondary'>@Chambre de Commerce Canada Afrique</strong>, qui accompagne ses membres dans l’intégralité de leur processus d’exportation de compétences, d’ouverture de filiale ou de solutionnement des problématiques liées à la pénurie.<br></br><br></br>
                C’est l’occasion de féliciter le Promoteur de <strong className='font-bold text-secondary'>@Cuisine santé Kids, le @Chef Dongue Guy</strong>,  une entreprise de l’Ontario francophone membre de ,<mark className='bg-transparent text-blue-600'>#La CCCA</mark> qui s’est exportée au Cameroun à travers #CFPHTC.
            </p>
        </div>
    )
}
