// Dashboard.js
import React, { useEffect } from "react";
import EventList from "../Components/EventList";
import { Calendar02Icon, GraduateMaleIcon, NewReleasesIcon, NewsIcon } from "hugeicons-react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvenement } from "../../../store/evenements.slice";

export default function Dashboard() {

  const data=[
    {
      title:'Newsletter',
      date:'',
      nbre:'100',
      symb:'',
      icon:<NewsIcon size={100}/>,
      color:'blue-600'
    },
    {
      title:'CCCA Revue',
      date:'',
      nbre:'20',
      symb:'',
      icon:<NewReleasesIcon size={100}/>,
      color:'secondary'
    },
    {
      title:'Évenements',
      date:'',
      nbre:'10',
      symb:'',
      icon:<Calendar02Icon size={100}/>,
      color:'green-500'
    },
    {
      title:'Formation',
      date:'',
      nbre:'27',
      symb:'',
      icon:<GraduateMaleIcon size={100}/>,
      color:'primary'
    },
  ]

  const dispatch = useDispatch();
  const { Evenements, isSuccess,isLoading } = useSelector((state) => state.evenements);
  useEffect(() => {
    dispatch(getAllEvenement());
  }, [dispatch]);
  
  return (
    // <div className="flex flex-col flex-1 bg-gray-100">
    <> 
    <h2 className="m-0 text-[1.5rem] font-semibold text-primary mb-4">Dashboard</h2>
    <div className="flex w-full flex-col gap-6">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {/* Cards for metrics in French */}
        {data?.map((x)=>
          <div className={`p-7 relative bg-${x.color} bg-opacity-20 shadow flex flex-col gap-3 rounded-lg`}>
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">{x.title}</h3>
              <span className="text-[.85rem]">{x.date}</span>
            </div>
            <p className={`text-[2.4rem] text-${x.color} font-semibold`}>{x.nbre} <sup className="text-md">{x.symb}</sup></p>
            <div className={`absolute w-[80px] z-10 text-${x.color} bottom-8 right-7 h-[80px] flex items-center justify-center rounded-[50px] border-secondary`}>{x?.icon}</div>
          </div>
        )}
      </div>

      <div className="flex w-full  max-md:flex-col gap-6">
        <div className="w-[60%] max-md:w-full flex flex-col gap-4 bg-white p-6 rounded-md">
          <span className="text-[1rem] font-semibold">Liste des évenements</span>
          <EventList event={Evenements}/>
        </div> 
        <div className="w-[40%] max-md:w-full py-10 bg-secondary bg-opacity-40 px-8 rounded-md shadow">
            test
        </div>
      </div>
    </div>
    </>
    // </div>
  );
}
