import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import {  FaPlus, FaTrashAlt,FaTrash, FaEdit,FaUser } from "react-icons/fa";
import Breads from "../Components/Breads";
import img1 from "../../../assets/1x/image.png";
import img2 from "../../../assets/1x/karl.jpg"
import { use } from "i18next";
export default function GestionUtilisateurs() {
    const [users, setUsers] = useState([
      { id: 1, nom: "Dupont", prenom: "Jean", email: "jean.dupont@example.com", role: "Utilisateur", telephone: "0612345678", image:img2 },
      { id: 2, nom: "Martin", prenom: "Claire", email: "claire.martin@example.com", role: "Admin", telephone: "0712345678", image:img1 },
    ]);
    const [editData, setEditData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const handleRoleChange = (id, newRole) => {
      setUsers((prev) =>
        prev.map((user) => (user.id === id ? { ...user, role: newRole } : user))
      );
    };

   
    
    const handleEdit = (user) => {
      setEditData(user);
      setDescription(user.description);
      setIsModalOpen(true);
    };
    const filteredData = users.filter(
      (user) =>
        user.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    
    const [showForm, setShowForm] = useState(false);
    
    const handleDelete = (id) => {
      setUsers(users.filter((user) => user.id !== id));
    };
    const breadcrumbLinks = [
      { label: "Acceuil", path: "/" },
      { label: "Rôles", path: "/Admin/GestionUtilisateurs" }
      
    ];
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
      <div className="flex flex-col gap-5">
        <Breads breadcrumbLinks={breadcrumbLinks} title="Rôles Utilisateurs"/>
      <div className="flex flex-col gap-3 items-center p-[3%] bg-white rounded-lg w-full">
        <div className="flex flex-col gap-3 w-full component">
          <div className="flex items-center max-md:flex-col gap-2 justify-between banerpp">
            <h3 className="text-nowrap text-[1.4rem] max-md:text-[1.1rem]">
            Rôles des Utilisateurs
            </h3>
            
            <div className="w-full flex max-md:grid max-md:grid-cols-3 text-center items-center gap-2 justify-end max-md:justify-center text-[.8rem]">
              <div className="bg-secondary cursor-pointer btn-filter text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                Filre
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                pdf
              </div>
            
            </div>
          </div>
          <div className="bg-white border border-[#3174ad] filter-component rounded-md overflow-hidden overflow-y-hidden transition-all duration-500">
            <div className="flex flex-col">
              <div className="flex items-center justify-center bg-[#3174ad] text-white p-2">
                <span className="text-center text-[1.1rem] max-md:text-[.9rem] font-medium capitalize">
                  Filtre
                </span>
              </div>
              <div className="p-4 filter-component py-5 grid grid-cols-2 max-md:grid-cols-1 gap-4">
                <input
                  type="text"
                  className="formulaire"
                  placeholder="Nom de l'utilisateur"
                ></input>
                <input
                  type="email"
                  className="formulaire"
                  placeholder="adresse mail de l'utilisateur"
                ></input>
               
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto w-full mt-4">
            <table className="min-w-full bg-white border border-gray-200 text-center">
              <thead className="bg-[#3174ad] text-white font-semibold capitalize whitespace-nowrap">
              <tr className="text-md">
              <th className="py-3 px-2 border-b">ID</th>
              <th className="py-3 px-2 border-b">profil</th>
              <th className="py-3 px-2 border-b">Nom</th>
                <th className="py-3 px-2 border-b">Prénom</th>
                <th className="py-3 px-2 border-b">E-mail</th>
                <th className="py-3 px-2 border-b">Téléphone</th>
                <th className="py-3 px-2 border-b">Rôle</th>
                <th className="py-3 px-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody className="text-md whitespace-nowrap">
                {currentItems.map((user) => (
                  <tr key={user.id} className="hover:bg-blue-50 border-b">
                    <td className="py-2 px-2">{user.id}</td>
                    <td className="py-2 px-2 flex items-center justify-center">

                    {user.image ? (
                <img src={user.image} alt={user.nom} className="w-[100px] h-[60px] rounded-md overflow-hidden" />
              ) : (
                <FaUser className="text-2xl text-gray-400 mx-auto mb-2" />
              )}
                    </td>
                    <td className="py-2 px-2 truncate table-cell">
                      {user.nom}
                    </td>
                    <td className="py-2 px-2 table-cell ">{user.prenom}</td>
                    <td className="py-2 px-2 table-cell">
                      {user.email}
                    </td>
                    <td className="py-2 px-2 table-cell">
                      {user.telephone}
                    </td>
                    
                    <td className="py-2 px-2 table-cell">
                  <select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                  >
                    <option value="Utilisateur">Utilisateur</option>
                    <option value="Admin">Admin</option>
                  </select>
                </td>
                   
                    <td className="py-2 px-2">
                      <div className="flex justify-center space-x-3">
                        <button
                          onClick={() => handleEdit(user)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <FaEdit  size={21}/>
                        </button>
                        <button
                          onClick={() => handleDelete(user.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash size={21}/>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
          </table>
        </div>
      </div>
      </div>
    );
  }
  