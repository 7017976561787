import React, { useState } from 'react';
import profile1 from '../../assets/1x/pdg.jpg';
import profile2 from '../../assets/1x/karl.jpg';
import profile3 from '../../assets/1x/tania.jpg';
import profile4 from '../../assets/1x/image.png';
import profile5 from '../../assets/1x/nzemba.jpg';
// import profile6 from '../../assets/1x/tessier.jpg';
import profile7 from '../../assets/1x/rachid.jpg';
import profile8 from '../../assets/1x/paquets.jpeg';
import profile9 from '../../assets/1x/dubois.jpg';
// import Annimated from '../../components/Annimated';
// import { Link } from 'react-router-dom';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

export default function BureauAfrique() {
    const[opens,setOpens]=useState(false)
    const[choix,setChoix]=useState('')
    const data = [
        {
            profile: profile9,
            name: 'Jérémy DUBOIS',
            titre: 'Responsable du Bureau Afrique de la CCCA',
            bibliog:[]
        },
      
        // {
        //     profile: profile6,
        //     name: 'Mr. Samuel Tessier',
        //     titre: 'Membre du CA de la CCCA',
        //     bibliog:[
        //         {
        //             image:profile6,
        //             // descrip:`<img class='float-left h-fit w-[30%] mr-4' src=${profile6} alt='#'/><strong class='font-bold text-[1.4rem]'>Mr. Samuel Tessier, CRIC, CFA, CAIA</strong></br>Depuis plus de dix ans, Samuel Tessier accompagne avec succès des familles d’investisseurs fortunés internationaux dans leurs démarches d’immigration permanente au Canada. Par le biais de programmes d’immigration par investissement destinés aux investisseurs et entrepreneurs, il a eu le privilège de soutenir plus d’une centaine de familles dans la réalisation de leurs projets de vie au Canada. Analyste Financier Agréé (CFA) et Consultant Réglementé en Immigration Canadienne (CRIC) sous le numéro de licence R522822, Samuel Tessier offre une expertise unique au carrefour de la finance internationale et des stratégies d’immigration.</br>En tant qu’entrepreneur, M. Tessier possède une compréhension approfondie des réalités auxquelles font face les familles en affaires. Il maîtrise les enjeux complexes d’allocation d’actifs, de transmission patrimoniale et de planification familiale, permettant ainsi une approche sur mesure et centrée sur les objectifs familiaux, la mobilité internationale, l’éducation et la sécurité des proches. Son expertise dans les situations complexes ouvre aux familles fortunées l’accès à des opportunités mondiales, tout en assurant une navigation sans encombre à travers les exigences de la réglementation canadienne.`
        //         }
        //     ]
        // }
        // ,
       
    ];

    const handleOpen=async(x)=>{
        await setChoix(x);
        setOpens(true)
    }

    const biblio= data?.find((x)=>x.name===choix)?.bibliog
    
    return (
        <>
        <Dialog Dialog open={opens} onClose={setOpens} className="relative z-[100]" >
              <DialogBackdrop
                  transition
                  className="fixed inset-0 bg-black bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />

              <div className="fixed inset-0 w-full overflow-y-auto z-100">
                  <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                      <DialogPanel
                          transition
                          className="relative transform w-[65%] max-md:w-[90%] max-sm:w-[99%] overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                      >
                          <div className="bg-white sm:pb-4">
                              <div className="sm:flex sm:items-start">
                                  <div className="w-full text-center sm:mt-0">
                                      <DialogTitle as="h1" className="text-[2.1rem] border-b-2 border-primary py-7 max-md:py-4 max-md:text-base font-semibold leading-6 text-gray-900">
                                          Biographie de {choix}
                                      </DialogTitle>
                                      <div className='flex flex-col gap-4 px-[5%] py-8'>
                                        <div className='flex flex-col gap-4 mt-2'>
                                            {biblio?.map((x)=>
                                            <p className='text-justify text-[1.1rem] max-md:text-[.95rem]' dangerouslySetInnerHTML={{__html:x.descrip}}></p>
                                            )}
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </DialogPanel>
                  </div>
              </div>
            </Dialog>
        <div className='grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-8'>
            {data.map((x, index) => (
                // <Link to={`/a/Apropos-de-nous/Conseil-d-administration/${encodeURIComponent(x.name)}`} k>
                    <div key={index} onClick={()=>handleOpen(x.name)} className='relative h-[325px] cursor-pointer transition-all duration-500 hover:scale-[1.01] hover:shadow-lg hover:shadow-slate-400 max-md:h-[300px] rounded-md overflow-hidden'>
                        <img className='absolute top-0 left-0 object-cover object-top w-full h-full' src={x.profile} alt={x.name} />
                        <div className='absolute bottom-1 w-[90%] rounded-md left-1/2 -translate-x-1/2 backdrop-blur-sm bg-primary bg-opacity-50 flex flex-col items-center justify-center py-3 px-2'>
                            <h6 className='font-bold text-white text-center'>{x.name}</h6>
                            <span className='text-[.9rem] text-white text-opacity-90 text-center'>{x.titre}</span>
                        </div>
                    </div>
                // </Link>
            ))}
        </div>
        </>
    );
}

