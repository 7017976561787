import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth.slice';
import userReducer from './user.slice';
import RevueReducer from './revue.slice';
import ActualiteReducer from './actualites.slice';
import EvenementsReducer from './evenements.slice';

export const store = configureStore ({
  reducer: {
    auth: authReducer,
    user: userReducer,
    revue: RevueReducer,
    actualites: ActualiteReducer,
    evenements: EvenementsReducer,
  },
  devTools: true,
});
