import axios from 'axios';
import { BaseUrl } from '../config';

const API_URL = BaseUrl+'/api/actualites/';

// Get user Voitures
const createActualite = async (userData) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.post(API_URL,userData);
  return response.data;
};

//getById
const getActualiteById = async (id) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.get(API_URL + id);

  // console.log (response.data.result);
  return response.data;
};

const getAllActualite= async() => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.get(API_URL);

  // console.log (response);
  return response.data;
};

const DeleteActualite = async (id) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.delete(API_URL + id);

  //   console.log (response.data.result);
  return response.data;
};

const updateActualite = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(API_URL,data, config);

  // console.log (response);
  return response.data;
};

const ActualitesService = {
  getAllActualite,
  createActualite,
  getActualiteById,
  DeleteActualite,
  updateActualite
};

export default ActualitesService;
