// src/pages/Admin/pages/GalerieAdmin.js
import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import image1 from '../../../assets/15846.jpg';
import image2 from '../../../assets/2148913227.jpg';
import Breads from '../Components/Breads';

function SortableItem({ id, url, onDelete }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 50 : 'auto', // Bring the dragged item to the top
    boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Add shadow for visual feedback
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="relative group">
      <img src={url} alt="Galerie" className="w-full h-full object-cover rounded-lg shadow-lg" />
      <button
        onClick={() => onDelete(id)}
        className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <FaTrash />
      </button>
    </div>
  );
}

export default function GalerieAdmin() {
  const [images, setImages] = useState([
    { id: '1', url: image1 },
    { id: '2', url: image2 },
    { id: '3', url: image1 },
    { id: '4', url: image2 },
    { id: '5', url: image1 },
    { id: '6', url: image2 },
    { id: '7', url: image1 },
    { id: '8', url: image2 },
    { id: '9', url: image1 },
    { id: '10', url: image2 },
    { id: '11', url: image1 },
    { id: '12', url: image2 },
  ]);

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  // Configure sensors for both mouse and touch
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200, // Adds delay for better handling on mobile
        tolerance: 10, // Tolerance for touch to initiate drag
      },
    })
  );

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImage = {
        id: Date.now().toString(),
        url: URL.createObjectURL(file),
      };
      setImages([...images, newImage]);
    }
  };

  const handleDelete = (id) => {
    setConfirmDeleteId(id); // Set the ID of the image to delete
  };

  const confirmDelete = () => {
    setImages(images.filter((image) => image.id !== confirmDeleteId)); // Update state immediately
    setConfirmDeleteId(null); // Reset the confirmation state
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = images.findIndex((image) => image.id === active.id);
      const newIndex = images.findIndex((image) => image.id === over.id);
      setImages((images) => arrayMove(images, oldIndex, newIndex));
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentImages = images.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(images.length / itemsPerPage);

  const handlePageChange = (page) => setCurrentPage(page);
  const breadcrumbLinks = [
    { label: "Acceuil", path: "/" },
    { label: "À propos", path: "/" },
    { label: "Galérie", path: "/Admin/à-propos/galéries" }
    // { label: "Product", path: "/product" },
  ];

  return (
    <div className="flex flex-col gap-5">
      <Breads breadcrumbLinks={breadcrumbLinks} title="Galéries"/>
    <div className="flex flex-col gap-3 items-center p-[3%] bg-white rounded-lg w-full">
    <div className="flex flex-col gap-3 w-full component">
          <div className="flex items-center max-md:flex-col gap-2 justify-between banerpp">
            <h3 className="text-nowrap text-[1.4rem] max-md:text-[1.1rem]">
            Galéries d'image
            </h3>
            <div className="w-full flex max-md:grid max-md:grid-cols-3 text-center items-center gap-2 justify-end max-md:justify-center text-[.8rem]">
              <div className="bg-secondary cursor-pointer btn-filter text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                Filre
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                pdf
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                imprimer
              </div>
            </div>
          </div>
        </div>
        <hr className='w-full border border-[#3174ad] my-3'/>
      {/* Drag and drop context */}
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={currentImages.map((image) => image.id)} strategy={verticalListSortingStrategy}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {currentImages.map((image) => (
              <SortableItem key={image.id} id={image.id} url={image.url} onDelete={() => handleDelete(image.id)} />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {/* Pagination */}
      <div className="flex justify-center mt-6 space-x-2">
        {[...Array(totalPages).keys()].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`px-3 py-1 rounded-full ${
              currentPage === index + 1 ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Floating Add Button for Mobile */}
      <button
        onClick={() => document.getElementById('fileInput').click()}
        className="fixed bottom-6 right-6 bg-red-600 text-white p-4 rounded-full shadow-lg sm:hidden hover:bg-red-700"
      >
        <FaPlus />
      </button>

      {/* Confirm Delete Modal */}
      {confirmDeleteId && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4">Confirmation de Suppression</h2>
            <p className="mb-6">Êtes-vous sûr de vouloir supprimer cette image ?</p>
            <div className="flex justify-end space-x-2">
              <button onClick={() => setConfirmDeleteId(null)} className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400">
                Annuler
              </button>
              <button onClick={confirmDelete} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
}
