import React from 'react'
import Navsbar from '../components/Navbar/Navsbar'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import $ from 'jquery'
import {ArrowUp02Icon } from 'hugeicons-react'
// import { motion } from 'framer-motion'

export default function Layouts() {
  const config = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit:{opacity:0}
  }

  const handleScrolle = () => {
    const scrol = $(window).scrollTop()
    // if (scrol >= 180) {
    //   $('.mobiles').addClass('sticky top-0')
    // }
    if (scrol <= 850) {
      $('.button-scrolltop').addClass('hidden').removeClass('flex')
      // if($('.button-scrolltop').hasClass('hidden')){
      //   $('.button-scrolltop').removeClass('hidden')
      // }
    }else{
      $('.button-scrolltop').removeClass('hidden').addClass('flex')
    }
    // console.log(scrol)
  }
  window.addEventListener('scroll', handleScrolle)
  $(document).ready(()=>{
    $('.button-scrolltop').on('click',()=>{
      $(document).scrollTop(0)
    })
  })
  return (
    <div className='relative' >
        <Navsbar />
        <Outlet />
        <Footer />
        <div className='fixed button-scrolltop hidden backdrop-blur-md items-center cursor-pointer justify-center bottom-10 max-md:bottom-5 max-md:right-5 right-10 bg-secondary z-10 bg-opacity-40 hover:bg-opacity-100 text-white w-[50px] transition-none duration-500 rounded-md  h-[45px]'>
          <ArrowUp02Icon />
        </div>
    </div>
  )
}
