// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import $ from 'jquery'
import FooterAdmin from '../Components/FooterAdmin';

function Layout() {

  $(document).ready(()=>{
    $('.btn-menu').on('click',()=>{
      $('.side-ccca').toggleClass('active');
      $('.corps-admin').toggleClass('active');
      $('.big-overlay').toggleClass('active');
    });
    $('.menu-close').on('click',()=>{
      $('.side-ccca').removeClass('active');
      $('.corps-admin').removeClass('active');
      $('.big-overlay').removeClass('active');
    })
  })

  $(function () {
    let toggleValue = false;
    $(document).on("click", ".btn-filter", function () {
      toggleValue = !toggleValue;
      const pixel = $('.banerpp').prop("scrollHeight");
      // const bap = $('.banerpp').prop("scrollHeight");
      if (toggleValue) {
        $(".filter-component").css("height", `calc(${2.5*pixel}px + 2rem)`);
        // $(this).children().last().addClass('rotate-[90deg]');
      } else {
        $(".filter-component").css("height", `0px`);
        // $(this).children().last().removeClass('rotate-[90deg]');
      }
    });
  });
  
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className='fixed w-full transition-all duration-300 bg-black bg-opacity-80 z-20 h-full top-0 left-0 big-overlay'></div>
      {/* Sidebar Component */}
      <Sidebar />

      {/* Main content area */}
      <div className="w-full transition-all duration-500 corps-admin flex flex-col  overflow-y-auto">
        {/* Navbar Component */}
        <Navbar />
        
        {/* Main content area where nested route content will appear */}
        <main className="flex-1 px-6 max-md:px-[4.8%] py-5 bg-gray-100">
          {/* Render the active nested route here */}
          <Outlet />
        </main>
        <FooterAdmin />
      </div>
    </div>
  );
}

export default Layout;
