import React, { useState } from "react";
import profile1 from "../../assets/1x/pdg.jpg";
import profile2 from "../../assets/1x/karl.jpg";
import profile3 from "../../assets/1x/tania.jpg";
import profile4 from "../../assets/1x/image.png";
import profile5 from "../../assets/1x/nzemba.jpg";
// import profile6 from '../../assets/1x/tessier.jpg';
import profile7 from "../../assets/1x/rachid.jpg";
import profile8 from "../../assets/1x/paquets.jpeg";
import profile9 from "../../assets/1x/dubois.jpg";
// import Annimated from '../../components/Annimated';
// import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";
import { Cancel01Icon } from "hugeicons-react";

export default function ConseilAdministration() {
  const [opens, setOpens] = useState(false);
  const [choix, setChoix] = useState("");
  const data = [
    {
      profile: profile1,
      name: "Dr. Armand NGAKETCHA",
      titre: "Président de la CCCA",
      bibliog: []
    },
    {
      profile: profile2,
      name: "Karl Miville de Chêne",
      titre: "Vice-Président de la CCCA",
      bibliog: [
        {
          descrip: `<img class='float-left h-fit w-[30%] mr-4' src=${profile2} alt='#'/>Monsieur <strong>Miville-de Chêne</strong>,</br> travaille sur la scène internationale depuis 1984. À la base un négociant de café, il se dédie depuis trente ans à la promotion du commerce, la formation en salle et en ligne d’exportateurs et de formateurs en appui au commerce extérieur, la gestion d’association de promotion de commerce, au développement du métier de négociant, chef de mission et au support de la PME exportatrice.`
        }
      ]
    },
    {
      profile: profile3,
      name: "Tania Louis",
      titre: "Sécrétaire Générale",
      bibliog: []
    },
    {
      profile: profile4,
      name: "Bruner Nozière",
      titre: "Membre du CA de la CCCA",
      bibliog: [
        {
          image: profile4,
          descrip: `<img class='float-left h-fit w-[30%] mr-4' src=${profile4} alt='#'/><strong class='font-bold'>Carrière :</strong> M. Bruner Nozière est un entrepreneur, coach et conférencier hors pair. Il a donné des conférences lors de forums internationaux au Canada 1 et l'Afrique 2 au démarrage 3 l’écosystème et les technologies émergentes telles que la blockchain. Il a mis en place le premier programme d'accélération de l'entrepreneuriat4  pour les jeunes Défi3737, qui a reçu 5 par la ville de Montréal et soutenu par le ministère de l'Économie, de la Science et de l'Innovation du gouvernement du Québec (M.E.S.I). Il a travaillé dans son pays natal, Haïti, en tant que banquier et pour la société Internet Mediacom en tant que comptable. Après avoir immigré au Canada en 2005, il a lancé sa <a class='text-blue-500 underline' href='https://www.beta.international/'>firme de consulting</a>. Il a été nominé deux fois Entrepreneur de l'année en 2008 par SOCLE et 2010 PAR RJCCQ En plus d'être formateur, il a récemment cofondé une entreprise de technologie financière (Fintech) <a class='text-blue-500 underline' href='http://netdollar.ca/'>Netdollar.</a>`
        },
        {
          image: "",
          descrip: `<strong>Implications</strong></br> Nozière s’implique dans beaucoup d’activités philanthropiques et bénévoles en tant que conférencier. En 2017 et 2018, il a donné des conférences au collège et à l'université pour promouvoir l'entrepreneuriat et la technologie. Il préconisait contre le décrochage scolaire en collaboration avec l'Association pour le Développement De la recherche et innovation Québec(ADRIQ). Il continue activement d'accompagner et encadrer des jeunes entrepreneurs du monde entier, comme mentor de la Fondation Richard Branson Center (Jamaïque) et FasterCapital (Dubaï). En 2010, il a eu une reconnaissance du ministre des Affaires étrangères et participé avec beaucoup commissions commerciales en Afrique et Haïti l'Office franco-québécois de la jeunesse6 (OFQJ).`
        },
        {
          image: "",
          descrip: `<strong>Reconnaissances</strong></br> Nommé parmi les 75 meilleurs immigrants RBC 2019 par Canadian Magazine, En 2008, M. Nozière a reçu du ministre de l'Emploi et de la Solidarité, Sam Hamad, le prix du public à titre d'entrepreneur de la Société Lavaloise Communautaire d'Emprunt (SOCLE) et du Réseau québécois de crédit communautaire (RQCC). Il a siégé à plusieurs conseils d'administration dont Zap.coop (Montréal-wifi), le comité de sélection de la Jeune Chambre de Commerce Métropolitaine et de la Jeune Chambre de Commerce d'Haïti (JCCH).`
        },
        {
          image: "",
          descrip: `<strong>Intérêts</strong></br> Il est l'auteur de deux e-books : Le Guide des opportunités d'affaires et le Guide de levée de capitaux.M. Nozière milite pour les jeunes en entrepreneuriat, ce qui lui a permis de voyager dans de nombreux pays d'Afrique, d'Europe et du Moyen-Orient afin de promouvoir l'entrepreneuriat et l'innovation. Sa devise est simple : #RIRE, #APPREDRE, #VIVRE, #AIMER. Parce qu'il croit en Dieu, aux gens et aux startups.`
        }
      ]
    },
    {
      profile: profile5,
      name: "Me. Déogratias Nzemba",
      titre: "Membre du CA de la CCCA",
      bibliog: [
        {
          image: profile5,
          descrip: `<img class='float-left h-fit w-[30%] mr-4' src=${profile5} alt='#'/>Founding and Managing Partner of the Law Firm, Mr Déogratias Nzemba is a former President of  Burundi Bar Association and Burundi Commercial Court .
                    He holds an LL.B  Degree from the University of Burundi.He equally has two certificates in Civil and
                    Commercial Mediation issued by the University of Sherbrooke and the Canadian Institute for
                    Conflict Resolution (CICR).Nzemba also has a certificate in Legal Practice of Investment issued by the
                    International Institute of Development Law (IDLI) in Rome,Italy and a certificate in the
                    Protection of the Private Sector issued by Clark Atlanta University in the USA . He is a member of  different organizations such like Burundi  Bar Association , Quebec Bar Association and East Africa Law Society (East  African Community). He practises in commercial law , international business
                    law, international business law, real estate law, insurance law and banking law, immigration law, mining law, Intellectual Property law and Alternative Dispute Resolution.
                    He has, among other things, advised and represented so many insurance companies in the areas of insurance ,telecommunication, international transport .He has been a lawyer of many international organizations (NGOs), hotels, banks, embassies, World Bank, etc.
                    He was also a member of the team which drafted the current  Burundi Commercial Code. For more than ten years, he has been consulted by the World Bank during the drafting of its Doing Business Report in Burundi .
                    He is the author of « La gestion d’affaires en droit civil burundais »
                    He is fluent in French , English , Kirundi and Swahili`
        }
      ]
    },
    // {
    //     profile: profile6,
    //     name: 'Mr. Samuel Tessier',
    //     titre: 'Membre du CA de la CCCA',
    //     bibliog:[
    //         {
    //             image:profile6,
    //             // descrip:`<img class='float-left h-fit w-[30%] mr-4' src=${profile6} alt='#'/><strong class='font-bold text-[1.4rem]'>Mr. Samuel Tessier, CRIC, CFA, CAIA</strong></br>Depuis plus de dix ans, Samuel Tessier accompagne avec succès des familles d’investisseurs fortunés internationaux dans leurs démarches d’immigration permanente au Canada. Par le biais de programmes d’immigration par investissement destinés aux investisseurs et entrepreneurs, il a eu le privilège de soutenir plus d’une centaine de familles dans la réalisation de leurs projets de vie au Canada. Analyste Financier Agréé (CFA) et Consultant Réglementé en Immigration Canadienne (CRIC) sous le numéro de licence R522822, Samuel Tessier offre une expertise unique au carrefour de la finance internationale et des stratégies d’immigration.</br>En tant qu’entrepreneur, M. Tessier possède une compréhension approfondie des réalités auxquelles font face les familles en affaires. Il maîtrise les enjeux complexes d’allocation d’actifs, de transmission patrimoniale et de planification familiale, permettant ainsi une approche sur mesure et centrée sur les objectifs familiaux, la mobilité internationale, l’éducation et la sécurité des proches. Son expertise dans les situations complexes ouvre aux familles fortunées l’accès à des opportunités mondiales, tout en assurant une navigation sans encombre à travers les exigences de la réglementation canadienne.`
    //         }
    //     ]
    // }
    // ,
    {
      profile: profile7,
      name: "Dr. Rachid Rouane",
      titre: "Membre du CA de la CCCA",
      bibliog: [
        {
          image: profile7,
          descrip: `<img class='float-left h-[290px] object-cover object-top w-[30%] mr-4' src=${profile7} alt='#'/>Dr Rachid Rouane natif de Mesloub (Kabylie) Algérie,  détient un Master en administration des affaires de l’Université du Québec à Montréal (UQAM) et d’un DBA (Doctorat en administration des affaires) concentration  management  et  entrepreneuriat de l’American University of  Leadership. Il est actuellement président du cabinet canadien en stratégies et consulting Inc (www.ccsconseil.ca ), professeur en entrepreneuriat au Centre de services scolaires Marguerite Bourgeoys, professeur invité à l’université canadienne au Congo (RDC), chercheur au GRDM  de  l’université  Canadienne-ASM  à Abidjan et professeur invité (Équipe | proj-info.com)( UCASM – Un passeport vers l'excellence (ucasm-ci.org)), professeur  et directeur  de  thèses  de  doctorat  à  l’International University of  leadership.  Mentor/consultant :</br> <ul class='list-disc pl-4'> <li>à Futurpreneur canada (<a class='underline text-blue-500' href='https://www.futurpreneur.ca/fr/quebec/'>https://www.futurpreneur.ca/fr/quebec/</a>)</li><li>à Youth Entrepreneurship Center Empowerment (<a class='text-blue-500 underline' href='https://www.groupewamo.org/'>https://www.helloasso.com/associations/youth-entrepreneurship-center-forempowerment-yece</a>)  Cameroun</li><li>à WAMO groupe (République Centre Africain): <a class='text-blue-500' href='https://www.groupewamo.org/'>https://www.groupewamo.org/</a>.</li></ul></br>Il possède plus de 25 années d’experience en enseignement collégial et universitaire et consultation en entrepreneuriat, en marketing et management au Canada et à l’international. Il a plusieurs publications en entrepreneuriat et management. </br><a class='text-blue-500 underline' href='www.edilivre.com'>www.edilivre.com</a> `
        }
      ]
    },
    {
      profile: profile8,
      name: "Stéphanie Paquet",
      titre: "Membre du CA de la CCCA",
      bibliog: [
        {
          image: "",
          descrip: `<img class='float-left h-fit w-[35%] mr-4' src=${profile8} alt='#'/><strong class='font-bold'>Mme Stéphanie Paquet, coordonnatrice SÉNIORE en SST, auditrice formatrice et conférencière est à votre service depuis 22 ans en Santé et sécurité au Travail.</strong></br>Elle fait son chemin dès 2002 avec un diplôme technique dans l’univers de procédés industriels du monde des pâtes et papiers de production. Elle sera la 1ere femme noire au Canada, en opération dans un moulin de papier, univers non traditionnel d’ailleurs. Stéphanie sera opératrice de machines à papiers variées et formatrice d’engin et elle ira chercher du savoir spécialisé universitaire en SST, des relations du travail ainsi qu’une immersion au droit du travail.</br>`
        },
        {
          image: "",
          descrip: `Diplômée universitaire en Santé et sécurité au travail suivi d’un certificat en relations du travail elle avance entre 2008 et 2016, elle œuvrera avec l’entreprise privée, de service et de construction industrielle suivis d’un détour vers les mutuelles de prévention.`
        },
        {
          image: "",
          descrip: `Faits marquants : <ul class='list-disc ps-8'>
                                <li>Juin 2016 : Ouverture de PREVENTUM CONSULTATION SST</li> 
                                <li>Novembre 2020 : Ouverture de PREVEVENTUM CONSTRUCTION SST</li>
                                </ul>
                                `
        },
        {
          image: "",
          descrip: `Un duo de cabinets-conseils en Santé et sécurité au travail au Canada  : <ul class='list-disc ps-8'>
                                <li>Octobre 2023 : Prospection et mise en place de PREVENTUM SST INTERNATIONAL – CAMEROUN.</li>
                                </ul>
                                `
        },
        {
          image: "",
          descrip: `Chef d’orchestre de ces 3 structures qui emploient plus de 50 ressources (multiethnique et multidisciplinaire) spécialisées, expertes séniores dans leurs domaines respectifs partout sur le globe. La première porte officielle est le Cameroun, mais elle a l’ambition de bonifier la vie au travail dans plusieurs autres pays africains, ayant eu l’occasion d’offrir les services entre autres dans le secteur minier.`
        },
        {
          image: "",
          descrip: `Facilitante avec les connexions humaines, Mme Paquet offre du service-conseil et expertises, des ajustements avec de stratégies gagnantes uniques et productives. Elle déploie également plus de 600 000 produits industriels de Nord-Américains pour la sécurité des individus et des installations. Ayant formé autant des chefs d’entreprises que leurs gestionnaires de haut niveau, que les milliers de travailleurs et ce, à façonner ensemble, le bien commun des entreprises. Elle aura permis à de nombreuses sociétés à se conformer et à se démarquer suite aux rapports professionnels émis. `
        },
        {
          image: "",
          descrip: `Mme Paquet vous propose aujourd’hui sa passion pour l’humain qui est au service du travail avec cette formule internationale afin de remettre ou maintenir la croissance en santé et sécurité aux premières loges de votre entreprise.`
        },
        {
          image: "",
          descrip: `Mme Stéphanie donne sa contribution avec intérêts dans diverses organisations sociales ou professionnelles tout en démontrant sa fibre entrepreneure. 
                                <ul class='list-disc ps-8'>
                                <li>2005-2007 : Création d’une mini entreprise en vermicompostage à l’École Alternative St-Sacrement de Trois-Rivières</li> 
                                <li>2018-2020 : Présidente de Corporation Valorisation solidaire (OBNL revitalisation des encombrants domestiques)</li>
                                <li>2017- 2020 : Membre du CA en tant que Secrétaire et finalement Vice-Présidente du Regroupement de femmes de Carrières de la Mauricie</li>
                                <li>2019 - 2023 : Membre du comité SST de consultation La Fédération des Chambres de Commerce du Québec (FCCQ) </li>
                                <li>2020 - Membre active dans plusieurs comités SST à l’Institut Robert Sauvé en Santé et sécurité au travail (IRSST)
                                    <ul class='list-disc ps-10'>
                                        <li>Comité sécurité du travail : Équipement de protection individuelle (EPI) inclue la sécurité machine, le cadenassage, protection contre les chutes, mesures d’ingénieries, etc. </li>
                                        <li>Comité Hygiène du travail : Risques chimiques et biologiques et la toxicologie, la contrainte thermique, etc. Gestion des chimiques et Mesures d’ingénieries.</li>
                                        </ul>
                                </li>
                                </ul>
                                `
        },
        {
          image: "",
          descrip: `Heureuse de rejoindre cette belle organisation, permettant des ponts importants sur 2 continents, elle espère travailler avec chacun d’entre vous à propulser les savoir-faire et être en mettant l’humain sous protection avec de meilleures pratiques.
À la bonne heure ! 
`
        },
        {
          descrip: `Mme Stéphanie Paquet, Présidente de Preventum SST (Canada) et Gérante) Preventum SST international – Cameroun. `
        }
      ]
    }
  ];

  const handleOpen = async (x) => {
    await setChoix(x);
    setOpens(true);
  };

  const biblio = data?.find((x) => x.name === choix)?.bibliog;

  return (
    <>
      <Dialog
        Dialog
        open={opens}
        onClose={setOpens}
        className="relative z-[100]"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 w-full overflow-y-auto z-100">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform w-[65%] max-md:w-[90%] max-sm:w-[99%] overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="w-full text-center sm:mt-0">
                    <DialogTitle
                      as="h1"
                      className="text-[2.1rem] border-b-2 border-primary py-7 max-md:py-4 max-md:text-base font-semibold leading-6 text-gray-900"
                    >
                      Biographie de {choix}
                    </DialogTitle>
                    <div
                      onClick={() => setOpens(false)}
                      className="absolute cursor-pointer flex items-center justify-center z-[100] top-[5.5%] right-[3%] w-[40px] h-[40px]"
                    >
                      <Cancel01Icon
                        fontSizeAdjust={200}
                        size={30}
                        fontWeight={600}
                      />
                    </div>
                    <div className="flex flex-col gap-4 px-[5%] py-8">
                      <div className="flex flex-col gap-4 mt-2">
                        {biblio?.map((x) => (
                          <p
                            className="text-justify text-[1.1rem] max-md:text-[.95rem]"
                            dangerouslySetInnerHTML={{ __html: x.descrip }}
                          ></p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-8">
        {data.map((x, index) => (
          // <Link to={`/a/Apropos-de-nous/Conseil-d-administration/${encodeURIComponent(x.name)}`} k>
          <div
            key={index}
            onClick={() => handleOpen(x.name)}
            className="relative h-[325px] cursor-pointer transition-all duration-500 hover:scale-[1.01] hover:shadow-lg hover:shadow-slate-400 max-md:h-[300px] rounded-md overflow-hidden"
          >
            <img
              className="absolute top-0 left-0 object-cover object-top w-full h-full"
              src={x.profile}
              alt={x.name}
            />
            <div className="absolute bottom-1 w-[90%] rounded-md left-1/2 -translate-x-1/2 backdrop-blur-sm bg-primary bg-opacity-50 flex flex-col items-center justify-center py-3 px-2">
              <h6 className="font-bold text-white text-center">{x.name}</h6>
              <span className="text-[.9rem] text-white text-opacity-90 text-center">
                {x.titre}
              </span>
            </div>
          </div>
          // </Link>
        ))}
      </div>
    </>
  );
}
