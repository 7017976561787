import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { FaPlus, FaUser, FaEdit,FaTrash, } from "react-icons/fa";
import photoPP from '../../../assets/1x/dubois.jpg'
import ReactQuill from "react-quill";
import Breads from "../Components/Breads";
import "react-quill/dist/quill.snow.css";

export default function GestionBureauAfrique() {
  const [description, settitre] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [BureauAfriqueData, setBureauAfriqueData] = useState([
        {
         id:1,
          profile: photoPP,
          name: "Jérémy DUBOIS",
          titre: "Responsable du Bureau Afrique de la CCCA",
          bibliog: ["Bibliographie du Responsable du Bureau Afrique de la CCCA"],
        },
      ]);
      const [editData, setEditData] = useState(null);
      const [ setNewMember] = useState({
        name: '',
        titre: '',
        profile: null,
      });
  const filteredData = BureauAfriqueData.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
  const [showForm, setShowForm] = useState(false);
  const [BureauAfrique, setBureauAfrique] = useState([]);
  const [formData, setFormData] = useState({ name: "", titre: "", bibliog:"", profile: null });
 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
 const handleCreateOrEditBureauAfrique = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const titre = event.target.titre.value;
    const bibliog = event.target.bibliog.value;
 
    

    const newBureauAfriqueItem = {
      id: editData ? editData.id : BureauAfriqueData.length + 1,
      name,
      titre,
      bibliog,
      profile: null
    };

    if (editData) {
      setBureauAfriqueData(
        BureauAfriqueData.map((item) => (item.id === editData.id ? newBureauAfriqueItem : item))
      );
    } else {
      setBureauAfriqueData([...BureauAfriqueData, newBureauAfriqueItem]);
    }

    setIsModalOpen(false);
  };
  const breadcrumbLinks = [
    { label: "Acceuil", path: "/" },
    { label: "À propos", path: "/Admin/à-propos/GestionBureauAfrique" },
    { label: "Conseil d'administration", path: "/Admin/à-propos/GestionBureauAfrique" }
    
  ];
  const handleEdit = (item) => {
    setEditData(item);
    settitre(item.titre);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setBureauAfriqueData(BureauAfriqueData.filter((item) => item.id !== id));
  };


  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, profile: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Titres (H1-H6)
        ["bold", "italic", "underline", "strike"], // Gras, italique, souligné, barré
        ["blockquote", "code-block"], // Citation et bloc de code
        [{ list: "ordered" }, { list: "bullet" }], // Listes ordonnées et non ordonnées
        [{ script: "sub" }, { script: "super" }], // Indices et exposants
        [{ indent: "-1" }, { indent: "+1" }], // Indentation
        [{ direction: "rtl" }], // Texte de droite à gauche
        [{ size: ["small", false, "large", "huge"] }], // Taille du texte
        [{ color: [] }, { background: [] }], // Couleur et couleur de fond
        [{ font: [] }], // Famille de police
        [{ align: [] }], // Alignement du texte
        ["link", "image", "video"], // Liens, images, vidéos
        ["clean"], // Effacer la mise en forme
    ],
  };
  
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "script",
    "indent",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
  ];
  

  const handleSave = () => {
    if (!formData.name || !formData.titre) {
      alert("Veuillez remplir tous les champs obligatoires !");
      return;
    }
    setBureauAfrique([...BureauAfrique, formData]);
    setFormData({ name: "", titre: "",bibliog:"", profile: null });
    setShowForm(false);
  };

  return (
    <div className="flex flex-col gap-5">
      <Breads breadcrumbLinks={breadcrumbLinks} title="Conseil Administration"/>
      <div className="flex flex-col gap-3 items-center p-[3%] bg-white rounded-lg w-full">
        <div className="flex flex-col gap-3 w-full component">
          <div className="flex items-center max-md:flex-col gap-2 justify-between banerpp">
            <h3 className="text-nowrap text-[1.4rem] max-md:text-[1.1rem]">
            Liste des Conseillers Administration
            </h3>
        <div className="w-full flex max-md:grid max-md:grid-cols-3 text-center items-center gap-2 justify-end max-md:justify-center text-[.8rem]">
          <div className="bg-secondary cursor-pointer btn-filter text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
            Filtre
          </div>
          <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
            PDF
          </div>
          <button
            onClick={() => setShowForm(!showForm)}
            className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize"
          >
            <FaPlus className="inline mr-2" /> Ajouter un responsable
          </button>
        </div>
      </div>

      {/* Filtre */}
      <div className="bg-white border border-[#3174ad] filter-component rounded-md overflow-hidden transition-all duration-500">
        <div className="flex flex-col">
          <div className="flex items-center justify-center bg-[#3174ad] text-white p-2">
            <span className="text-center text-[1.1rem] max-md:text-[.9rem] font-medium capitalize">
              Filtre
            </span>
          </div>
          <div className="p-4 py-5 grid grid-cols-1 gap-4">
            <input
              type="text"
              className="formulaire w-full border border-gray-300 rounded-lg p-2"
              placeholder="Nom du responsable"
            />
          </div>
        </div>
      </div>

      {/* Liste des services */}
      <div className="overflow-x-auto w-full mt-4">
            <table className="min-w-full bg-white border border-gray-200 text-center">
              <thead className="bg-[#3174ad] text-white font-semibold capitalize whitespace-nowrap">
                <tr className="text-md">
                  <th className="py-3 px-2 border-b">
                    ID
                  </th>
                  <th className="py-3 px-2 border-b">
                    image
                  </th>
                  <th className="py-3 px-2 border-b">
                  Nom
                  </th>
                  
                  <th className="py-3 px-2 border-b">
                    titre
                  </th>
                  <th className="py-3 px-2 border-b">
                  bibliographie
                  </th>
                 
                  <th className="py-3 px-2 border-b">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-md whitespace-nowrap">
                {currentItems.map((item) => (
                  <tr key={item.id} className="hover:bg-blue-50 border-b">
                    <td className="py-2 px-2">{item.id}</td>
                    <td className="py-2 px-2 flex items-center justify-center">

                    {item.profile ? (
                <img src={item.profile} alt={item.name} className="w-[100px] h-[80px] rounded-md overflow-hidden" />
              ) : (
                <FaUser className="text-2xl text-gray-400 mx-auto mb-2" />
              )}
                    </td>
                    <td className="py-2 px-2 truncate table-cell">
                      {item.name}
                    </td>
                    <td className="py-2 px-2 table-cell ">{item.titre}</td>
                    <td className="py-2 px-2 table-cell ">{item.bibliog}</td>
                    
                   
                    <td className="py-2 px-2">
                      <div className="flex justify-center space-x-3">
                        <button
                          onClick={() => handleEdit(item)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <FaEdit  size={21}/>
                        </button>
                        <button
                          onClick={() => handleDelete(item.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash size={21}/>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

      {/* Boîte de dialogue pour ajouter un responsable */}
      <Dialog
          Dialog
          open={showForm}
          onClose={setShowForm}
          className="relative z-[100]"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />
  
          <div className="fixed inset-0 max-w-screen overflow-y-auto z-100">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in max-md:w-full sm:my-8 sm:w-full sm:max-w-[60%] data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                {loading && (
                  <div
                    role="status"
                    className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="w-full mt-3 text-center sm:mt-0">
                      <DialogTitle
                        as="h1"
                        className="text-[1.6rem] mb-8 font-semibold leading-6 text-gray-900"
                      >
                        Responsables bureaux d'Afrique
                      </DialogTitle>
                      <div className="flex flex-col gap-4 mt-2">
                        {/* <div className='flex items-center gap-2'>Formation:
                                                  <span className='text-secondary font-semibold text-center leading-5 text-[.85rem]'>{choix}</span></div> */}
  
                        <form className="w-full flex flex-col gap-4">
                        <div className="mb-4">
                <label className="block text-gray-700 mb-2">Photo</label>
                <label className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-1 hover:border-blue-400 transition duration-200 cursor-pointer">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoUpload}
                    className="hidden"
                  />
                  {formData.profile ? (
                    <img src={formData.profile} alt={formData.name} className="object-cover w-full h-48 rounded-md" />
                  ) : (
                    <>
                      <div className="text-blue-600">
                        <FaUser className="text-6xl" />
                      </div>
                      <p className="text-gray-600">Déposez ou <span className="text-blue-600">sélectionnez</span></p>
                    </>
                  )}
                </label>
              </div>
                          <div className="grid grid-cols-2 gap-4">
                            <input
                              type="text"
                              className="formulaire"
                              value={formData.name}
                              placeholder="Nom du responsable"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  name: e.target.value
                                })
                              }
                              required
                            />
                             <input
                              type="text"
                              className="formulaire"
                              value={formData.titre}
                              placeholder="Titre du responsable"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  titre: e.target.value
                                })
                              }
                              required
                            />
                          
                                </div>
                                <div className="mb-16 max-md:mb-1 w-full">
                                <ReactQuill
                                    // value={eventDetails.descriptionText}
                                    // onChange={handleDescriptionChange}
                                    modules={quillModules}
                                    formats={formats}
                                    style={{height:250}}
                                    value={formData.bibliog}
                                    theme="snow"
                                    placeholder="Entrez la bibliographie du responsable..."
                                    className="bg-white w-full h-[500px] md:h-40"
                                    onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          bibliog: e.target.value
                                        })
                                      }
                                  />
                                  {/* <Editor /> */}
                                  </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    Sauvegarder
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setShowForm(false)}
                    className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Annuler
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
    </div>
    </div>
    </div>
    

  );
}
