import React, { useState } from "react";
import ReactQuill from "react-quill";
import imagePlaceholder from "../../../assets/1234.jpg";
import "react-quill/dist/quill.snow.css";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";
import { FaEdit, FaTrash, FaPlus, FaUser } from "react-icons/fa";
import Breads from "../Components/Breads";

export default function ServicesAdmin() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const [servicesData, setServicesData] = useState([
    {
      id: 1,
      title: "Service de Consultation",
      description: "Consultation pour fournir des solutions sur mesure.",
      image: imagePlaceholder
    },
    {
      id: 2,
      title: "Support Technique",
      description: "Support technique pour résoudre vos problèmes rapidement.",
      image: imagePlaceholder
    }
  ]);

  const [editData, setEditData] = useState(null);

  const handleEdit = (item) => {
    setEditData(item);
    setDescription(item.description);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setServicesData(servicesData.filter((item) => item.id !== id));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const filteredData = servicesData.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const breadcrumbLinks = [
    { label: "Acceuil", path: "/" },
    { label: "services", path: "/Admin/servicesAdmin" }
  ];

  // const [selectedSlot, setSelectedSlot] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    start: null,
    end: null
  });

  // const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Titres (H1-H6)
      ["bold", "italic", "underline", "strike"], // Gras, italique, souligné, barré
      ["blockquote", "code-block"], // Citation et bloc de code
      [{ list: "ordered" }, { list: "bullet" }], // Listes ordonnées et non ordonnées
      [{ script: "sub" }, { script: "super" }], // Indices et exposants
      [{ indent: "-1" }, { indent: "+1" }], // Indentation
      [{ direction: "rtl" }], // Texte de droite à gauche
      [{ size: ["small", false, "large", "huge"] }], // Taille du texte
      [{ color: [] }, { background: [] }], // Couleur et couleur de fond
      [{ font: [] }], // Famille de police
      [{ align: [] }], // Alignement du texte
      ["link", "image", "video"], // Liens, images, vidéos
      ["clean"] // Effacer la mise en forme
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "script",
    "indent",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video"
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div className="flex flex-col gap-5">
      <Breads breadcrumbLinks={breadcrumbLinks} title="services" />
      <div className="flex flex-col gap-3 items-center p-[3%] bg-white rounded-lg w-full">
        <div className="flex flex-col gap-3 w-full component">
          <div className="flex items-center max-md:flex-col gap-2 justify-between banerpp">
            <h3 className="text-nowrap text-[1.4rem] max-md:text-[1.1rem]">
              Liste des services
            </h3>
            <div className="w-full flex max-md:grid max-md:grid-cols-3 text-center items-center gap-2 justify-end max-md:justify-center text-[.8rem]">
              <div className="bg-secondary cursor-pointer btn-filter text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                Filre
              </div>
              <div className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize">
                pdf
              </div>
              <button
                onClick={() => setShowForm(!showForm)}
                className="bg-secondary text-white px-6 py-2 max-md:py-1.5 bg-opacity-90 rounded-[5px] font-medium capitalize"
              >
                <FaPlus className="inline mr-2" /> Ajouter
              </button>
            </div>
          </div>
          <div className="bg-white border border-[#3174ad] filter-component rounded-md overflow-hidden overflow-y-hidden transition-all duration-500" style={{height:0}}>
              <div className="flex flex-col">
                <div className="flex items-center justify-center bg-[#3174ad] text-white p-2">
                  <span className="text-center text-[1.1rem] max-md:text-[.9rem] font-medium capitalize">
                    Filtre
                  </span>
                </div>
                <div className="p-4 filter-component">
                <form className="grid grid-cols-3 max-md:grid-cols-1 gap-4">
                  <input
                    type="text"
                    className="formulaire"
                    placeholder="Titre de l'evenement"
                  ></input>
                  <input
                    type="text"
                    className="formulaire"
                    placeholder="Lieux de l'evenement"
                  ></input>
                  <input
                    type="date"
                    className="formulaire"
                    placeholder="lieux"
                  ></input>
                  </form>
                </div>
              </div>
            </div>
        </div>

        {/* Responsive Toggle Views */}
        <div className="overflow-x-auto w-full mt-4">
          <table className="min-w-full bg-white border border-gray-200 text-center">
            <thead className="bg-[#3174ad] text-white font-semibold capitalize whitespace-nowrap">
              <tr className="text-md">
                <th className="py-3 px-2 border-b">ID</th>
                <th className="py-3 px-2 border-b">image</th>
                <th className="py-3 px-2 border-b">Nom</th>

                <th className="py-3 px-2 border-b">description</th>
                <th className="py-3 px-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody className="text-md whitespace-nowrap">
              {currentItems.map((item) => (
                <tr key={item.id} className="hover:bg-blue-50 border-b">
                  <td className="py-2 px-2">{item.id}</td>
                  <td className="py-2 px-2 flex items-center justify-center">
                    {item.image ? (
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-[100px] h-[60px] rounded-md overflow-hidden"
                      />
                    ) : (
                      <FaUser className="text-2xl text-gray-400 mx-auto mb-2" />
                    )}
                  </td>
                  <td className="py-2 px-2 truncate table-cell">
                    {item.title}
                  </td>
                  <td className="py-2 px-2 truncate table-cell">
                    {item.description}
                  </td>

                  <td className="py-2 px-2">
                      <div className="flex justify-center space-x-3">
                        <button
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <FaEdit size={21} />
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash size={21} />
                        </button>
                      </div>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex items-center justify-center md:justify-end w-full max-w-6xl mt-4 space-x-1">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-l-md hover:bg-gray-300"
          >
            ← Précédent
          </button>
          {[...Array(totalPages).keys()].map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`px-3 py-1 ${
                currentPage === index + 1
                  ? "bg-red-600 text-white"
                  : "bg-gray-200 text-gray-600"
              } hover:bg-gray-300`}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-r-md hover:bg-gray-300"
          >
            Suivant →
          </button>
        </div>
      </div>
    </div>
  );
}
